import { Transition } from "@headlessui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FilterIcon from "src/assets/filter.svg";
import { Button, Input, Table } from "src/components";
import Breadcrumb from "src/components/ui/breadcrumb.component";
import { counterActions } from "src/context/counter-slice";
import { stockistActions } from "src/context/stockist-slice";
import { RootState } from "src/context/store";
import { useOutsideClick } from "src/hooks/useOutsideClick";
import { useToast } from "src/hooks/useToast";
import { getAllCompanyDetails } from "src/services/company-api";
import { getAllCityDetails } from "src/services/product-api";
import { queryStockistDetails } from "src/services/stockist-api";
import { appendClientParams, appendParams } from "src/utils/utils";
import StockistsFilter from "./stockist-filter.component";
import StockistLayout from "./stockist-layout.component";

const columnHelper = createColumnHelper();

const StockistMaster = () => {
  const { companyList, cityList } = useSelector((state: RootState) => state.counter);
  const { filters } = useSelector((state: RootState) => state.stockist);
  // const { divisionList } = useSelector((state: RootState) => state.division);
  const [isFiltersShown, setIsFiltersShown] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stockistData, setStockistData] = useState([]);
  // const [isFiltersShown, setIsFiltersShown] = useState<boolean>(false);

  const navigate = useNavigate();
  const { toast } = useToast();

  const [searchQuery, setSearchQuery] = useState<string>("");

  const dispatch = useDispatch();

  useEffect(() => {
    getAllCityDetails().then((res) => {
      dispatch(counterActions.setCityList(res?.data?.data));
    });
  }, []);

  useEffect(() => {
    getAllCompanyDetails()
      .then((res) => {
        dispatch(counterActions.setCompanyList(res?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: string = event.target.value;

    let backendParams: string | null | undefined = "";

    // if (value?.toString().startsWith("DR") || value?.toString().startsWith("dr")) {
    //   value = value.toUpperCase().substring(0, 2) + value.substring(2);
    //   backendParams = "?counter_code=" + value;
    // } else if (isNaN(Number(value))) backendParams = "?name=" + value;
    // else
    backendParams = "?search=" + value;

    setSearchQuery(value);

    if (value === "") {
      setStockistData([]);
    } else {
      queryStockistDetails(backendParams)
        .then((res) => setStockistData(res?.data?.data))
        .catch((err: any) => {
          console.log("err", err);
          toast({ description: "Error fetching data" });
        });
    }
  };

  const retrieveCounterHandler = async () => {
    try {
      setIsLoading(true);
      let backendParams = "?";
      let clientParams = "";

      const companyClientParams = appendClientParams(Array.isArray(filters?.company) ? filters.company : [], "c", "name", "code");
      const companyParams = appendParams(Array.isArray(filters?.company) ? filters.company : [], "company_code", "code");

      const cityParams = appendParams(Array.isArray(filters?.city) ? filters.city : [], "city_code", "city_code");
      const cityClientParams = appendClientParams(Array.isArray(filters?.city) ? filters.city : [], "rc", "city_name", "city_code");

      backendParams += [companyParams, cityParams].filter(Boolean).join("&");

      clientParams += [companyClientParams, cityClientParams].filter(Boolean).join("&");

      if (backendParams === "?") {
        backendParams = "";
      }

      if (clientParams === "?") {
        clientParams = "";
      }

      console.log("backendParams", backendParams);

      // navigate(`/Masters/stockists?${clientParams.toString()}`);
      const res = await queryStockistDetails(backendParams);
      if (res?.status === 200) {
        setStockistData(res?.data?.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  console.log("companyList", companyList);

  const columns = useMemo(
    () => [
      columnHelper.accessor("medvol_code", {
        cell: (info) => <p> {info.getValue()} </p>,
        header: () => "Medvol Code",
        enableSorting: true,
      }),
      columnHelper.accessor("firm_name", {
        cell: (info: any) => <div className="pl-3"> {info.getValue()}</div>,
        header: () => "Stockist Name",
        enableSorting: true,
      }),

      columnHelper.accessor("city", {
        cell: (info: any) => <div className="pl-3">{info.getValue()?.city_name}</div>,
        header: () => "City",
        enableSorting: true,
      }),
      columnHelper.accessor("city", {
        cell: (info: any) => <div className="pl-3">{info.getValue()?.district_code.district_name}</div>,
        header: () => "District",
        enableSorting: true,
      }),
      columnHelper.accessor("city", {
        cell: (info: any) => <div className="pl-3">{info.getValue()?.district_code.state_code?.state_name}</div>,
        header: () => "State",
        enableSorting: true,
      }),
      columnHelper.accessor("doctor_name", {
        cell: (info) => (
          <Button onClickCapture={() => rowHandler(info.row)} className="text-blue-600 underline focus:ring-blue-600">
            View
          </Button>
        ),
        header: () => "Action",
        enableSorting: true,
      }),
    ],
    []
  );

  // const retrieveCounterHandler = async () => {
  //   try {
  //     setIsLoading(true);

  //     const res = await getStockist();
  //     if (res?.status === 200) {
  //       // setCounterDetails(res.data);
  //       setStockistData(res?.data?.data);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const rowHandler = (row: any) => {
    window.sessionStorage.setItem("stockistMasterId", row.original?.stockist_id ?? "");
    dispatch(stockistActions.setSelectedStockistData({}));
    navigate(`/masters/stockists/details`);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log(searchQuery);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchQuery]);

  const ref = useOutsideClick(() => {
    setIsFiltersShown(false);
  });

  return (
    <StockistLayout>
      <Transition
        as={Fragment}
        show={isFiltersShown}
        enter="transition transition ease-in-out duration-200 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition  ease-in-out duration-200 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="fixed right-0 inset-0 top-0 z-[500]">
          <StockistsFilter
            ref={ref}
            companyDetails={companyList || []}
            // divisionDetails={[]}
            cityDetails={cityList || []}
            // linkDetails={[]}
            onCloseClick={() => setIsFiltersShown(false)}
          />
        </div>
      </Transition>
      <header className="flex px-[3.75rem] items-center justify-between py-5 border-b-[1px] border-b-[#E9E9E9]">
        <div>
          <Breadcrumb
            currentActive="Stockists"
            dataOrigin={[
              { name: "Masters", path: "masters" },
              { name: "Stockist", path: "stockists" },
            ]}
          />
          <h1 className="text-[#49484C] font-bold text-[2rem]">Stockist Database</h1>
        </div>

        <div className="flex items-center gap-2 ">
          <Input placeholder="Filter" className="py-[0.7rem] px-3 rounded-lg" showIcon={true} value={searchQuery} onChange={handleQuery} />
          <Button
            className="gap-1 px-4 py-3 border"
            onClick={() => {
              setIsFiltersShown(true);
            }}
          >
            Filters <img src={FilterIcon} className="w-5 h-5" />
          </Button>
          <Button
            onClick={retrieveCounterHandler}
            isLoading={isLoading}
            loaderSize="medium"
            className="bg-[#F3EFFC] py-2 px-4 border-2 text-[#42267F] border-[#7F56D9] ml-auto font-semibold text-base"
          >
            Retrieve
          </Button>
        </div>
      </header>

      <main>
        <div className="px-[3.75rem] my-10">
          <Table columns={columns} data={stockistData} onRowClick={rowHandler} />
        </div>
      </main>
    </StockistLayout>
  );
};

export default StockistMaster;
