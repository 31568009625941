/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Spinner } from "src/components";

import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { counterKeysArray, employeeKeysArray, productKeysArray } from "src/constants/versioning";
import { RootState } from "src/context/store";
import { versioningActions } from "src/context/versioning-slice";
import { useToast } from "src/hooks/useToast";
import {
  getCounterVersioning,
  getEmployeeVersioning,
  getGeographyVersioning,
  getProductVersioning,
  getStockistVersioning,
} from "src/services/events-api";
import Dot from "../../assets/dot.png";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover.components";

interface ReplaceProductProps {
  // dataSource: Array<any>;
  type: string;
}

interface DataProps {
  message: string;
  time: string;
  eventId: number | string;
  streamId: number | string;
  reference_number: string | null | undefined;
  created_by: {
    name: string;
    role: string;
    code: string;
  };
}

const VersioningCrate: React.FC<ReplaceProductProps> = ({ type }: ReplaceProductProps) => {
  const { toast } = useToast();
  const dispatch = useDispatch();
  const { productVersioning } = useSelector((state: RootState) => state.versioning);
  const { selectedData } = useSelector((state: RootState) => state.master);
  const primary_key = useSelector((state: RootState) => state.geography.primary_key);
  const { selectedCounterData } = useSelector((state: RootState) => state.counter);
  const { selectedEmployeeData } = useSelector((state: RootState) => state.employee);
  const { selectedStockistData } = useSelector((state: RootState) => state.stockist);

  // const [selectedFilter, setSelectedFilter] = useState<any>({});
  // const [selectedCase, setselectedCase] = useState<any>({});
  const [isFetching, setIsFetching] = useState(false);

  let keysArray: any = [];
  switch (type) {
    case "Product":
      keysArray = productKeysArray;
      break;
    case "Employee":
      keysArray = employeeKeysArray;
      break;
    case "Counter":
      keysArray = counterKeysArray;
      break;
    case "Geography":
      break;
    default:
      break;
  }

  const fetchVersioningData = async (data?: Record<string, unknown>) => {
    try {
      console.log("selectedData", selectedData);
      console.log("data", data);
      setIsFetching(true);

      // setselectedCase((data: any) => data?.label);
      let res;
      switch (type) {
        case "Product":
          res = await getProductVersioning(selectedData?.company_productcode, data?.value, data?.sendIn);
          break;
        case "Employee":
          res = await getEmployeeVersioning(selectedEmployeeData?.empmaster_id, data?.value, data?.sendIn); // Need to change it to employee specific code
          break;
        case "Counter":
          res = await getCounterVersioning(selectedCounterData?.counter_id, data?.value, data?.sendIn); //Need to change it to counter specific code
          break;
        case "Geography":
          res = await getGeographyVersioning(primary_key);
          break;
        case "Stockist":
          res = await getStockistVersioning(selectedStockistData?.stockist_id);
          break;
        default:
          console.error("Invalid type");
          res = { status: 400 };
          break;
      }
      if (res?.status === 200) {
        setIsFetching(false);
        console.log("res", res);
        dispatch(versioningActions.setProductVersioning(res.data.logs));
        toast({
          title: "Success",
          description: `${type} versioning data fetched successfully`,
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      setIsFetching(false);

      console.error("err", error);
      toast({
        title: "Error",
        description: `Failed to fetch ${type} versioning data`,
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    fetchVersioningData();
  }, []);

  const VersionCard = ({ data }: { data: DataProps }) => {
    if (!data.message || !data.time || !data.created_by) {
      return null;
    }
    const { message, time, created_by, eventId, streamId, reference_number } = data;
    const { name, role, code } = created_by || {};
    const formattedTime = moment(time).fromNow();

    return (
      <div>
        <div className="items-center justify-center overflow-y-scroll align-middle ">
          <div className=" border-b-[1px] border-b-[#999999] mt-5 relative" />
          <p className="text-sm text-center text-[#999999] bg-white mt-[-10px] z-auto absolute self-center right-[38%] px-5">
            {moment(time).format("DD/MM/YYYY hh:mm A")}
          </p>
          <p className="flex text-[12px] mt-6 align-middle items-center text-[#49484C]">
            {name} <img src={Dot} alt="Dot" className="w-4 h-4" /> {code} <img src={Dot} alt="Dot" className="w-4 h-4" /> {role}
            <img src={Dot} alt="Dot" className="w-4 h-4" /> {eventId ? `event ${eventId}` : `event -`} <img src={Dot} alt="Dot" className="w-4 h-4" />{" "}
            {streamId ? `stream ${streamId}` : `stream -`}
          </p>
          <p className="flex text-[14px] mt-2 align-middle items-center text-[#49484C] gap-1">
            <p className="font-semibold">{message}</p>
          </p>
          <div className="flex items-center justify-between mt-2 align-middle">
            <p className="text-xs">{formattedTime}</p>
            <p className=" text-sm text-[#586AF5] cursor-pointer">
              <Popover>
                <PopoverTrigger>View Reference</PopoverTrigger>
                <PopoverContent className="bg-white">{reference_number}</PopoverContent>
              </Popover>
            </p>
            {/* dummy commit */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <div
        id="scrollArea"
        className="p-6 w-[26rem] absolute max-h-[99.9%] h-[99.9%] bg-white right-0 -mt-6 z-50  rounded-md shadow-2xl overflow-y-scroll "
      >
        <Autocomplete
          onSelectionChange={(data: any) => {
            fetchVersioningData(data);
          }}
          inputClassName="w-full h-auto"
          placeholder="Filter"
          suggestionList={keysArray}
          displayKey="label"
          uniqueKey="id"
        />
        {isFetching && (
          <div className="flex justify-center my-5">
            <Spinner size="medium" />
          </div>
        )}
        {[...productVersioning].map((data, index) => (
          <VersionCard key={index} data={data} />
        ))}
      </div>

      {/* <Modal backdropClose={false} isOpen={open} onCloseModal={setOpen} isHeader={false}>*/}
      {/*  <div className="p-6 text-center w-[15rem] shadow-md flex flex-wrap">{referenceNumber}</div>*/}
      {/*</Modal>*/}
    </Fragment>
  );
};

export default VersioningCrate;
