import { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterAccordion from "src/container/filter-accordion.component";
import { stockistActions } from "src/context/stockist-slice";
import { RootState } from "src/context/store";

interface FilterItem {
  id?: string;
  name?: string;
  code?: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any; // Add an index signature
}

interface StockistFilter {
  onCloseClick: () => void;
  companyDetails: FilterItem[];
  divisionDetails?: FilterItem[];
  linkDetails?: FilterItem[];
  cityDetails: FilterItem[];
}

const Divider = () => <div className="w-full h-[0.0625rem] bg-[#0000001a]" />;

const StockistsFilter = forwardRef<HTMLElement, StockistFilter>(({ companyDetails, cityDetails }, ref) => {
  const { filters } = useSelector((state: RootState) => state.stockist);
  const dispatch = useDispatch();

  const companyFilterHandler = (details: FilterItem[]) => {
    dispatch(stockistActions.setCompanyFilter(details));
  };

  // const divisionFilterHandler = (details: FilterItem[]) => {
  //   dispatch(stockistActions.setDivisionFilter(details));
  // };

  // const linkFilterHandler = (details: FilterItem[]) => {
  //   dispatch(stockistActions.setLinkFilter(details));
  // };

  const cityFilterHandler = (details: FilterItem[]) => {
    dispatch(stockistActions.setCityFilter(details));
  };

  return (
    <aside
      className="bg-white border-l-[1px] min-w-[20rem] overflow-y-auto max-w-[25rem] border-l-[#00000014] fixed px-8 text-[#49484C] text-xl font-semibold py-5 right-0  h-screen"
      ref={ref}
    >
      <div className="flex items-center justify-between gap-5 mt-3">
        <h3 className="whitespace-nowrap">Fetch filters</h3>
      </div>

      {/* filters  */}
      <div className="flex flex-col ">
        {/* <FilterAccordion
          uniqueKey="division_name"
          displayKey="division_name"
          title="Division"
          arrayToFilter={divisionDetails}
          onSelectingFilter={divisionFilterHandler}
          defaultValue={filters.division}
        /> */}
        {/* <Divider /> */}
        <FilterAccordion
          uniqueKey="code"
          displayKey="shortname"
          onSelectingFilter={companyFilterHandler}
          title="Company Name"
          arrayToFilter={companyDetails}
          defaultValue={filters.company}
        />
        <Divider />

        <FilterAccordion
          uniqueKey="city_code"
          displayKey="city_name"
          title="City"
          defaultValue={filters.city}
          onSelectingFilter={cityFilterHandler}
          arrayToFilter={cityDetails}
        />

        <Divider />
        {/* <FilterAccordion
          displayKey="dr_ph_lnk_code"
          title="Link Type"
          arrayToFilter={linkDetails}
          uniqueKey="id"
          onSelectingFilter={linkFilterHandler}
          defaultValue={filters.link}
        /> */}
      </div>
    </aside>
  );
});

export default StockistsFilter;
