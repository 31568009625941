import { Transition } from "@headlessui/react";
import { SortingState } from "@tanstack/react-table";
import { FilterIcon } from "lucide-react";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Table from "../../components/Table";
import { getCraColumns } from "../../constants";
import CounterCreateForm from "../../container/CounterCreateForm";
import CountersFilter from "../../container/CounterFilter";
import FormSuccess from "../../container/FormSuccess";
import { craActions } from "../../context/cra-slice";
import { RootState } from "../../context/store";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { usePagination } from "../../hooks/usePagination";
import { getCounterRequestBasedOnCreq, getIsActionAllowed, queryCounterRequestBasedOnPosition } from "../../services/cda-api";
import { queryDivisionDetails } from "../../services/division-api";
import { appendParams } from "../../utils/utils";
import { getDistrictDetails } from "src/services/region-api";

const HomeApprovedTab = () => {
  const auth: any = useSelector((state: RootState) => state.auth);
  const { tableData, craFiltersData, ApprovedByMeFilters } = useSelector((state: any) => state.cra);
  const [loading, setLoading] = useState(false);
  const [isLoadingImport, setIsLoadingImport] = useState(false);
  const [isImportForm, setIsImportForm] = useState(false);
  const [importFormData, setImportFormData] = useState<any>({
    lowestEmployee: {},
    type: {},
    selectedDivision: "",
    counter_request_code: "",
  });

  const [showErrorModal, setShowErrorModal] = useState(false);

  const [isFiltersShown, setIsFiltersShown] = useState<boolean>(false);

  const { pagination, onPaginationChange, pageIndex, pageSize, resetPagination } = usePagination();
  const [totalRowCount, setTotalRowCount] = useState(10);
  const [sorting, setSorting] = useState<SortingState>();

  const [globalSearch, setGlobalSearch] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [_, setSearchParams] = useSearchParams();

  const ref = useOutsideClick(() => {
    setIsFiltersShown(false);
  });

  function rowClickHandler(row: any) {
    const rowData = row.original;

    const requestId = rowData?.counterRequestDetail?.counterRequest_dtl_id || "";

    window.sessionStorage.setItem("requestId", requestId);
    dispatch(craActions.setSelectedData({}));
    navigate("/request-approval/cra/details");
  }

  const fetchDetails = () => {
    if (!auth?.currentPsr?.emp_position_hdr?.position_code) return;
    setLoading(true);
    setSearchParams({
      pageSize: pageSize.toString(),
      pageIndex: pageIndex.toString(),
    });

    const { key, sortingOrder } = getSortingKey();

    const companyParams = appendParams(Array.isArray(ApprovedByMeFilters?.company) ? ApprovedByMeFilters.company : [], "company_id", "code");
    const divisionParams = appendParams(
      Array.isArray(ApprovedByMeFilters?.division) ? ApprovedByMeFilters.division : [],
      "division_id",
      "company_divisioncode"
    );
    const linkParams = appendParams(Array.isArray(ApprovedByMeFilters?.link) ? ApprovedByMeFilters.link : [], "linktype_id", "drph_lnk_type_id");
    const stateParams = appendParams(Array.isArray(ApprovedByMeFilters?.state) ? ApprovedByMeFilters.state : [], "state_id", "state_code");
    const districtParams = appendParams(Array.isArray(ApprovedByMeFilters?.district) ? ApprovedByMeFilters.district : [], "district_id", "district_code");
    const statusParams = appendParams(Array.isArray(ApprovedByMeFilters?.status) ? ApprovedByMeFilters.status : [], "status", "value");

    const cityParams = appendParams(Array.isArray(ApprovedByMeFilters?.city) ? ApprovedByMeFilters.city : [], "city_id", "city_code");

    let filtersQuery = [companyParams, divisionParams, linkParams, stateParams, districtParams, cityParams, statusParams].join("&");

    filtersQuery.endsWith("&&") && (filtersQuery = filtersQuery.slice(0, -1));
    filtersQuery.endsWith("&") && (filtersQuery = filtersQuery.slice(0, -1));

    queryCounterRequestBasedOnPosition(
      auth?.currentPsr?.emp_position_hdr?.position_code || "",
      auth?.currentPsr?.company_code,
      "type=approved",
      pageIndex - 1,
      key,
      sortingOrder,
      filtersQuery,
      globalSearch
    )
      .then((res) => {
        // const transformedData = res?.data?.data?.map((item: any) => flattenObject(item));
        dispatch(craActions.setTableData(res?.data?.data || []));
        setTotalRowCount(res?.data?.totalCount);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const handleRedraftClick = async (event: any, data: any) => {
    try {
      event.stopPropagation();
      setIsLoadingImport(true);
      const { counter_request_code } = data;
      const response = await getCounterRequestBasedOnCreq(counter_request_code);
      if (response?.status !== 200) throw new Error();

      const lowestEmployee = {
        uid: response?.data?.On_Behalf_PositionCode_Data?.data?.[0]?.uid,
        emp_position_hdr: { position_code: response?.data?.On_Behalf_PositionCode },
      };

      const selectedDivision = response?.data?.company_division_code;

      const type = {
        id: response?.data?.drph_lnk_type?.drph_lnk_type_id,
        ...response?.data?.drph_lnk_type,
      };

      setImportFormData({
        lowestEmployee,
        selectedDivision,
        type,
        counter_request_code,
      });

      await checkIfActionIsAllowed(selectedDivision, lowestEmployee, type, response?.data);
    } catch (error) {
      setIsLoadingImport(false);
      setShowErrorModal(true);
      console.log(error);
    }
  };

  async function checkIfActionIsAllowed(company_divisioncode: any, currentLowestPSR: any, type: any, responseData: any) {
    try {
      let query = "";
      const position_code = auth.isAdmin ? currentLowestPSR?.emp_position_hdr?.position_code : auth?.currentPsr?.emp_position_hdr?.position_code;

      const company_code = company_divisioncode?.company_code?.code;

      query = `?position_code=${position_code}&counter_linktype=${type?.dr_ph_lnk_code?.toString()}&division_code=${company_divisioncode?.company_divisioncode?.toString()}&actiontype=post&company_code=${company_code}`;
      const res = await getIsActionAllowed(query);

      if (res?.status !== 200) {
        throw new Error();
      }

      if (!res?.data?.allowed) {
        throw new Error();
      }

      dispatch(craActions.setFormDefaultValues(responseData));

      setIsImportForm(true);
    } catch (error) {
      dispatch(craActions.setFormDefaultValues({}));
      setShowErrorModal(true);
      setIsLoadingImport(false);
    }
  }

  useEffect(() => {
    fetchDetails();
  }, [auth?.currentPsr, pageSize, pageIndex]);

  let pageCount = Math.ceil(totalRowCount / (pageSize ?? 1));
  if (totalRowCount % 10 === 0) {
    pageCount--;
  }

  const getSortingKey = () => {
    const counterRequestCode = sorting?.find((item: any) => item.id === "counterRequestDetail_counter_request_code");
    const counterRequestName = sorting?.find((item: any) => item.id === "counterRequestDetail_counter_name");
    const createdDate = sorting?.find((item: any) => item.id === "created_date");
    if (counterRequestCode) {
      const sortingOrder = counterRequestCode?.desc ? "DESC" : "ASC";
      return {
        key: "counter_request_code",
        sortingOrder,
      };
    } else if (counterRequestName) {
      const sortingOrder = counterRequestName?.desc ? "DESC" : "ASC";
      return {
        key: "counter_name",
        sortingOrder,
      };
    } else if (createdDate) {
      const sortingOrder = createdDate?.desc ? "DESC" : "ASC";
      return {
        key: "created_date",
        sortingOrder,
      };
    } else {
      return {
        key: "created_date",
        sortingOrder: "DESC",
      };
    }
  };

  let timerId: any;
  const handleSearch = (searchQuery: string) => {
    if (timerId) clearTimeout(timerId);

    timerId = setTimeout(() => {
      resetPagination();
      setGlobalSearch(searchQuery);
    }, 500);
  };

  useEffect(() => {
    fetchDetails();
  }, [sorting, globalSearch]);

  useEffect(() => {
    if (ApprovedByMeFilters?.company?.length) {
      queryDivisionDetails(`/details/?fields=division_name&company_code=${ApprovedByMeFilters?.company[0]?.code}`)
        .then((res) => dispatch(craActions.setCraFiltersData({ key: "approvedByMeDivision", value: res.data })))
        .catch((err) => console.log(err));
    } else {
      dispatch(craActions.setCraFiltersData({ key: "approvedByMeDivision", value: [] }));
      dispatch(craActions.setCRAFilter({ isApprovedByMe: true, key: "division", value: [] }));
    }
  }, [ApprovedByMeFilters?.company]);

  useEffect(() => {
    if (ApprovedByMeFilters?.state?.length) {
      getDistrictDetails(`?cityOnly=true&state_code=${ApprovedByMeFilters?.state[0]?.state_code || ""}`).then((res) => {
        dispatch(craActions.setCraFiltersData({ key: "approvedByMeDistrict", value: res.data.data }));
        const allCities = res.data.data.flatMap((district: any) => district.cities.map((city: any) => city));
        dispatch(craActions.setCraFiltersData({ key: "approvedByMeCity", value: allCities }));
      });
    } else {
      dispatch(craActions.setCraFiltersData({ key: "approvedByMeDistrict", value: [] }));
      dispatch(craActions.setCRAFilter({ isApprovedByMe: false, key: "district", value: [] }));
      dispatch(craActions.setCraFiltersData({ key: "approvedByMeCity", value: [] }));
      dispatch(craActions.setCRAFilter({ isApprovedByMe: false, key: "city", value: [] }));
    }
  }, [ApprovedByMeFilters?.state]);

  return (
    <Fragment>
      <Transition
        as={Fragment}
        show={isFiltersShown}
        enter="transition transition ease-in-out duration-200 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition  ease-in-out duration-200 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="fixed right-0 inset-0 top-0 z-[500]">
          <CountersFilter
            isApprovedByMe={true}
            ref={ref}
            companyDetails={craFiltersData?.company || []}
            divisionDetails={craFiltersData?.approvedByMeDivision || []}
            cityDetails={craFiltersData?.approvedByMeCity || []}
            linkDetails={craFiltersData?.link || []}
            stateDetails={craFiltersData?.state || []}
            districtDetails={craFiltersData?.approvedByMeDistrict || []}
            approvedByMeStatus={craFiltersData?.approvedByMeStatus || []}
            onCloseClick={() => setIsFiltersShown(false)}
          />
        </div>
      </Transition>

      {auth?.isAdmin && (
        <div className="flex justify-end float-right gap-2">
          <Button
            className="gap-1 px-4 mt-2 ml-2 border "
            onClick={() => {
              setIsFiltersShown(true);
            }}
          >
            Filters <FilterIcon className="w-5 h-5" />
          </Button>
          <Button onClick={fetchDetails} loaderSize="small" className="h-auto py-2 mt-2 border">
            Retrieve
          </Button>
        </div>
      )}
      <Table
        headClass="bg-[#dee2ff] text-[#586AF5]"
        isLoading={loading}
        columns={getCraColumns(handleRedraftClick, isLoadingImport)}
        title="Approved Counter Requests"
        subtitle="View and manage pending counter requests"
        data={tableData ?? []}
        onRowClick={rowClickHandler}
        isGlobalSearch={true}
        manualPagination={true}
        pageCount={pageCount}
        pagination={pagination}
        onPaginationChange={onPaginationChange}
        manualSorting={true}
        sorting={sorting}
        onSortingChange={setSorting}
        onGlobalSearch={handleSearch}
        searchPlaceholder="Search by counter request code"
      />
      {isImportForm && (
        <Modal
          isOpen={isImportForm}
          isHeader={true}
          onCloseModal={() => {
            setIsImportForm(false);
            setIsLoadingImport(false);
          }}
          title={`Adopt Counter  ${importFormData?.type?.dr_ph_lnk_code ? `- ${importFormData?.type?.dr_ph_lnk_code}` : ""}`}
        >
          <CounterCreateForm
            onCancel={() => {
              setIsImportForm(false);
              setIsLoadingImport(false);
            }}
            selectedDivision={importFormData?.selectedDivision}
            isAdoptForm={false}
            isImportForm={true}
            lowestEmployee={importFormData?.lowestEmployee}
            type={importFormData?.type}
            importCreqCode={importFormData?.counter_request_code}
            onFormSubmit={() => {
              setIsImportForm(false);
              setIsLoadingImport(false);
            }}
          />
        </Modal>
      )}

      {showErrorModal && (
        <FormSuccess
          showForm={showErrorModal}
          onContinue={() => {
            setShowErrorModal(false);
            setIsLoadingImport(false);
          }}
          variant="error"
          popupMessage={{
            description: "You don't have permission to perform this action",
            title: "Access Denied!",
          }}
        />
      )}
    </Fragment>
  );
};

export default HomeApprovedTab;
