import { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { craActions } from "../context/cra-slice";
import { RootState } from "src/context/store";
import FilterAccordion from "./FilterAccordion";

interface FilterItem {
  id?: string;
  name?: string;
  code?: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any; // Add an index signature
}

interface CounterFilter {
  onCloseClick: () => void;
  companyDetails: FilterItem[];
  divisionDetails: FilterItem[];
  cityDetails: FilterItem[];
  linkDetails: FilterItem[];
  stateDetails: FilterItem[];
  approvedByMeStatus?: FilterItem[];
  districtDetails: FilterItem[];
  isApprovedByMe?: boolean;
}

const Divider = () => <div className="w-full h-[0.0625rem] my-2 bg-[#0000001a]" />;

const CountersFilter = forwardRef<HTMLElement, CounterFilter>(
  ({ companyDetails, divisionDetails, cityDetails, isApprovedByMe = false, linkDetails, stateDetails, districtDetails, approvedByMeStatus }, ref) => {
    const { ApprovedByMeFilters, PendingFilters } = useSelector((state: RootState) => state.cra);
    const dispatch = useDispatch();

    const companyFilterHandler = (details: FilterItem[]) => {
      dispatch(craActions.setCRAFilter({ isApprovedByMe, key: "company", value: details }));
    };

    const divisionFilterHandler = (details: FilterItem[]) => {
      dispatch(craActions.setCRAFilter({ isApprovedByMe, key: "division", value: details }));
    };

    const cityFilterHandler = (details: FilterItem[]) => {
      dispatch(craActions.setCRAFilter({ isApprovedByMe, key: "city", value: details }));
    };

    const linkFilterHandler = (details: FilterItem[]) => {
      dispatch(craActions.setCRAFilter({ isApprovedByMe, key: "link", value: details }));
    };

    const statusFilterHandler = (details: FilterItem[]) => {
      dispatch(craActions.setCRAFilter({ isApprovedByMe, key: "status", value: details }));
    };

    const stateFilterHandler = (details: FilterItem[]) => {
      dispatch(craActions.setCRAFilter({ isApprovedByMe, key: "state", value: details }));
    };

    const districtFilterHandler = (details: FilterItem[]) => {
      dispatch(craActions.setCRAFilter({ isApprovedByMe, key: "district", value: details }));
    };

    const filters = isApprovedByMe ? ApprovedByMeFilters : PendingFilters;

    return (
      <aside
        className="bg-white border-l-[1px] min-w-[20rem] overflow-y-auto max-w-[25rem] border-l-[#00000014] fixed px-8 text-[#49484C] text-xl font-semibold py-5 right-0  h-screen"
        ref={ref}
      >
        <div className="flex items-center justify-between gap-5 mt-3">
          <h3 className="whitespace-nowrap">Fetch filters</h3>
        </div>

        {/* filters  */}
        <div className="flex flex-col ">
          <FilterAccordion
            uniqueKey="code"
            displayKey="shortname"
            onSelectingFilter={companyFilterHandler}
            title="Company Name"
            arrayToFilter={companyDetails}
            defaultValue={filters.company}
          />
          <Divider />
          <FilterAccordion
            uniqueKey="division_name"
            displayKey="division_name"
            title="Division"
            arrayToFilter={divisionDetails}
            onSelectingFilter={divisionFilterHandler}
            defaultValue={filters.division}
          />

          <Divider />

          <FilterAccordion
            uniqueKey="state_code"
            displayKey="state_name"
            title="State"
            defaultValue={filters.state}
            onSelectingFilter={stateFilterHandler}
            arrayToFilter={stateDetails}
          />
          <Divider />

          <FilterAccordion
            uniqueKey="city_code"
            displayKey="city_name"
            title="City"
            defaultValue={filters.city}
            onSelectingFilter={cityFilterHandler}
            arrayToFilter={cityDetails}
          />

          <Divider />
          <FilterAccordion
            uniqueKey="district_code"
            displayKey="district_name"
            title="District"
            defaultValue={filters.district}
            onSelectingFilter={districtFilterHandler}
            arrayToFilter={districtDetails}
          />

          <Divider />
          <FilterAccordion
            displayKey="dr_ph_lnk_code"
            title="Link Type"
            arrayToFilter={linkDetails?.filter((item: FilterItem) => item?.dr_ph_lnk_code !== "DRNT" && item?.dr_ph_lnk_code !== "INDPH-NT")}
            uniqueKey="drph_lnk_type_id"
            onSelectingFilter={linkFilterHandler}
            defaultValue={filters.link}
          />

          {approvedByMeStatus && approvedByMeStatus?.length > 0 && (
            <>
              <Divider />
              <FilterAccordion
                displayKey="label"
                title="Status"
                arrayToFilter={approvedByMeStatus || []}
                uniqueKey="value"
                onSelectingFilter={statusFilterHandler}
                defaultValue={filters.status}
              />
            </>
          )}
        </div>
      </aside>
    );
  }
);

export default CountersFilter;
